#main{
    overflow: hidden;
    height: 100vh!important;
}

.bleu{
    position: absolute;
    top: 0%;
    left: 0%;
    fill: #009fff;
    z-index: 0;
}

.rouge{
    position: absolute;
    bottom: -2%;
    right: -2%;
    fill: #ec2f4b;
    z-index: 0;
}