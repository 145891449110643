// Laptop
@media screen and (min-width: 1200px) and (max-width: 4000px) {

     .link-modal{
          font-family: Poppins;
          font-size: 18px;
          font-weight: 300;
          text-align: center!important;
          display: block;
          margin: auto;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.53;
          letter-spacing: 1px;
          text-align: center;
          text-decoration: none;
          color: #009fff;
     }
     .modal-green{
          border: 10px solid #3B7302;
     }
     
     .modal-red{
          border: 10px solid #ec2f4b;
     }
     .display-br{
          display: block;
     }
.conteneur{
     width: 95%;
     margin: 2.5%;
     display: block;
     text-align: center;
     box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
     background-color: #fafafa;
     height: 90vh;
     position: relative;
     border-radius: 10px;
     -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
     -ms-border-radius: 10px;
     -o-border-radius: 10px;
 }
 h1{
     font-family: 'ModernEraBold', sans-serif;
     font-size: 40px;
     font-weight: bold;
     font-style: normal;
     font-stretch: normal;
     line-height: 1.23;
     letter-spacing: normal;
     text-align: center;
     color: #212121;
     padding-bottom: 1%;
}


h2{
     font-family: 'ModernEraRegular', sans-serif;
     font-size: 18px;
     font-weight: normal;
     font-style: normal;
     font-stretch: normal;
     line-height: 1.2;
     letter-spacing: normal;
     text-align: center;
     color: #212121;
}

.link{
     border-radius: 37px;
     border: solid 1px #009fff;
     background-color: #fafafa;
     padding: 1%;
     width: 39%;
     flex: auto;
     margin: auto;
     overflow: hidden;
     font-family: Poppins;
     font-size: 16px;
     font-weight: 300;
     font-style: normal;
     font-stretch: normal;
     line-height: 1.53;
     letter-spacing: 2.04px;
     text-align: center;
     text-decoration: none;
     color: rgba(33, 33, 33, 0.55);
     -webkit-border-radius: 37px;
     -moz-border-radius: 37px;
     -ms-border-radius: 37px;
     -o-border-radius: 37px;
}

     #logo{
          position        : absolute;
          top             : 7%;
          width           : 100%;
          // background-color: orange;

          #retour{
               position: absolute;
               left: 10%;
               top: 50%;
               width: 15%;
               text-decoration: none;
               display: inline-block;

               img[alt="retour"]{
                    padding-right: 10%;
                    display: inline-block;
               }
          }

          #retour::after {
               content: " Retour";
               color: rgba(33, 33, 33, 0.55);
               text-decoration: none;
          }
     }

     #top{
          position        : absolute;
          top             : 33%;
          width           : 100%;
          // background-color: green;

          #UneBonneReponse{
               font-family: Poppins;
               font-size: 13px;
               font-weight: 300;
               color: rgba(33, 33, 33, 0.55);
          }
     }

     #center{
          display        : flex;
          flex-direction : row;
          flex-wrap      : nowrap;
          justify-content: flex-start;
          align-items    : center;
          align-content  : center;
          position       : absolute;
          bottom         : 15%;
          width          : 85%;
          padding        : 0% 7.5%;
          margin         : auto;
          // background-color: purple;

          .centered{
               margin-left: 3%;
               margin-right: 3%;
          }
     }

     #center2{
          flex-direction : row;
          flex-wrap      : nowrap;
          justify-content: flex-start;
          align-items    : center;
          align-content  : center;
          position       : absolute;
          bottom         : 15%;
          width          : 85%;
          padding        : 0% 7.5%;
          margin         : auto;
          // background-color: purple;

          .centered{
               margin-left: 3%;
               margin-right: 3%;
          }

          .link2{
               border-radius        : 37px;
               box-shadow           : 0 0 40px 0 rgba(0, 0, 0, 0.05);
               border               : solid 1px #009fff;
               background-color     : #fafafa;
               padding              : .5% 1%;
               width                : 600px;
               display              : block;
               margin               : auto;
               font-family          : Poppins;
               font-size            : 16px;
               font-weight          : 300;
               font-style           : normal;
               font-stretch         : normal;
               line-height          : 1.53;
               letter-spacing       : 2.04px;
               text-align           : center;
               text-decoration      : none;
               color                : rgba(33, 33, 33, 0.55);
               -webkit-border-radius: 37px;
               -moz-border-radius   : 37px;
               -ms-border-radius    : 37px;
               -o-border-radius     : 37px;
          }
     }

     #center3{
          display        : flex;
          flex-direction : row;
          flex-wrap      : nowrap;
          justify-content: flex-start;
          align-items    : center;
          align-content  : center;
          position       : absolute;
          bottom         : 15%;
          width          : 32%;
          padding        : 0% 35%;
          margin         : auto;
          // background-color: purple;

          .centered3{
               margin-right: 2%;
          }
          a{
               padding: 1% 0%;
          }
     }

     .centered4{
          margin-right: 2%;
          margin-left: 2%;
     }

     #bottom{
          position        : absolute;
          bottom          : 5%;
          width           : 100%;
          // background-color: yellow;
     }

     .modal {
          outline: none;
          font-size: 1.6rem;
          width: 100%;
          max-width: 100%;
          margin: 15%;
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          z-index: 15;
          will-change: transform;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          transform: translate3d(0, 0, 0);
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
          -o-transform: translate3d(0, 0, 0);
     }

     .modal2{
          padding: 5%;
          // background-color: orange;
          background-color: #fafafa;

          h3{
               font-family: 'ModernEraRegular', sans-serif;
               font-size: 30px;
               font-weight: normal;
               font-style: normal;
               font-stretch: normal;
               line-height: 1.2;
               letter-spacing: normal;
               text-align: center;
               color: #212121;
               padding-bottom: 5%;
          }

          p{
               font-family: Poppins;
          font-size: 18px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.53;
          letter-spacing: 1px;
          text-align: center;
          text-decoration: none;
          color: rgba(33, 33, 33, 0.55);
          }
     }

     .close{
          position: absolute;
          top: 8%;
          right: 2%;
          padding: 1%;
          border: none;
          font-family: 'ModernEraRegular', sans-serif;
          font-size: 15px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.23;
          letter-spacing: normal;
          text-align: center;
          //background-color: #fafafa;
          background-color:rgba(33, 33, 33, 0.2);
          border-radius: 100%;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          -ms-border-radius: 100%;
          -o-border-radius: 100%;
     }

     .display-br{
          display: none;
     }
}

// Tablette 
@media screen and (min-width: 0px) and (max-width: 1200px) {

     .link-modal{
          font-family: Poppins;
          font-size: 18px;
          font-weight: 300;
          text-align: center!important;
          display: block;
          margin: auto;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.53;
          letter-spacing: 1px;
          text-align: center;
          text-decoration: none;
          color: #009fff;
     }
     .modal-green{
          border: 10px solid #3B7302;
     }
     
     .modal-red{
          border: 10px solid #ec2f4b;
     }
     .display-br{
          display: block;
     }
     .conteneur{
          width: 90%;
          margin: 5%;
          display: block;
          text-align: center;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          background-color: #fafafa;
          height: 93vh;
          position: relative;
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
      }

     .supertest{
          display: block;
          background-color: red;
          height: 10vh;
     }

     .modal {
          outline: none;
          font-size: 1.6rem;
          width: 100%;
          max-width: 100%;
          margin: 5%;
          position: relative;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          z-index: 15;
          will-change: transform;
          box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
          border-radius: 10px;
          -webkit-border-radius: 10px;
          -moz-border-radius: 10px;
          -ms-border-radius: 10px;
          -o-border-radius: 10px;
          transform: translate3d(0, 0, 0);
          -webkit-transform: translate3d(0, 0, 0);
          -moz-transform: translate3d(0, 0, 0);
          -ms-transform: translate3d(0, 0, 0);
          -o-transform: translate3d(0, 0, 0);
     }

     .modal2{
          padding: 5%;
          // background-color: orange;
          background-color: #fafafa;

          h3{
               font-family: 'ModernEraRegular', sans-serif;
               font-size: 30px;
               font-weight: normal;
               font-style: normal;
               font-stretch: normal;
               line-height: 1.2;
               letter-spacing: normal;
               text-align: center;
               color: #212121;
               padding-bottom: 5%;
          }

          p{
               font-family: Poppins;
          font-size: 18px;
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.53;
          letter-spacing: 1px;
          text-align: center;
          text-decoration: none;
          color: rgba(33, 33, 33, 0.55);
          }
     }

     .close{
          position: absolute;
          top: 10%;
          right: 5%;
          padding: 1% 1.5%;
          border: none;
          font-family: 'ModernEraRegular', sans-serif;
          font-size: 15px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.23;
          letter-spacing: normal;
          text-align: center;
          //background-color: #fafafa;
          background-color:rgba(33, 33, 33, 0.2);
          border-radius: 100%;
          -webkit-border-radius: 100%;
          -moz-border-radius: 100%;
          -ms-border-radius: 100%;
          -o-border-radius: 100%;
     }

     .Quizz{
          z-index: 1;
     h1{
          //    background-color: red;
          font-family: 'ModernEraBold', sans-serif;
          font-size: 40px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.23;
          letter-spacing: normal;
          text-align: center;
          color: #212121;
          padding-bottom: 1%;
     }

     h2{
          font-family: 'ModernEraRegular', sans-serif;
          font-size: 23px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.2;
          letter-spacing: normal;
          text-align: center;
          color: #212121;
     }


     .link{
          border-radius        : 37px;
          box-shadow           : 0 0 40px 0 rgba(0, 0, 0, 0.05);
          border               : solid 1px #009fff;
          background-color     : #fafafa;
          padding              : 1.5% 1%;
          width                : 600px;
          display              : block;
          margin               : auto;
          font-family          : Poppins;
          font-size            : 16px;
          font-weight          : 300;
          font-style           : normal;
          font-stretch         : normal;
          line-height          : 1.53;
          letter-spacing       : 2.04px;
          text-align           : center;
          text-decoration      : none;
          color                : rgba(33, 33, 33, 0.55);
          -webkit-border-radius: 37px;
          -moz-border-radius   : 37px;
          -ms-border-radius    : 37px;
          -o-border-radius     : 37px;
     }

          #logo{
               position        : absolute;
               top             : 7%;
               width           : 100%;
               // background-color: orange;

               #retour{
                    position: absolute;
                    left: 10%;
                    top: 50%;
                    width: 15%;
                    text-decoration: none;
                    display: inline-block;
                    img[alt="retour"]{
                         padding-right: 10%;
                         display: inline-block;
                    }
               }

               #retour::after {
                    content: " Retour";
                    color: rgba(33, 33, 33, 0.55);
                    text-decoration: none;
               }
          }

          #top{
               position        : absolute;
               top             : 33%;
               width           : 100%;
               // background-color: green;

               #UneBonneReponse{
                    font-family: Poppins;
                    font-size: 13px;
                    font-weight: 300;
                    color: rgba(33, 33, 33, 0.55);
               }
          }

          #center{
               position        : absolute;
               bottom          : 15%;
               width           : 100%;
               // background-color: purple;
          }

          #bottom{
               position        : absolute;
               bottom          : 5%;
               width           : 100%;
               // background-color: yellow;
          }
     }

     #center2{
          flex-direction : row;
          flex-wrap      : nowrap;
          justify-content: flex-start;
          align-items    : center;
          align-content  : center;
          position       : absolute;
          bottom         : 15%;
          width          : 85%;
          padding        : 0% 7.5%;
          margin         : auto;
          // background-color: purple;

          .centered{
               margin-left: 3%;
               margin-right: 3%;
          }

          .link2{
               border-radius        : 37px;
               box-shadow           : 0 0 40px 0 rgba(0, 0, 0, 0.05);
               border               : solid 1px #009fff;
               background-color     : #fafafa;
               padding              : 1.5% 1%;
               width                : 600px;
               display              : block;
               margin               : auto;
               font-family          : Poppins;
               font-size            : 16px;
               font-weight          : 300;
               font-style           : normal;
               font-stretch         : normal;
               line-height          : 1.53;
               letter-spacing       : 2.04px;
               text-align           : center;
               text-decoration      : none;
               color                : rgba(33, 33, 33, 0.55);
               -webkit-border-radius: 37px;
               -moz-border-radius   : 37px;
               -ms-border-radius    : 37px;
               -o-border-radius     : 37px;
          }
     }

     #center3{
          flex-direction : row;
          flex-wrap      : nowrap;
          justify-content: flex-start;
          align-items    : center;
          align-content  : center;
          position       : absolute;
          bottom         : 15%;
          width          : 85%;
          padding        : 0% 7.5%;
          margin         : auto;
          // background-color: purple;

          .centered{
               margin-left: 3%;
               margin-right: 3%;
          }

          .link2{
               border-radius        : 37px;
               box-shadow           : 0 0 40px 0 rgba(0, 0, 0, 0.05);
               border               : solid 1px #009fff;
               background-color     : #fafafa;
               padding              : .5% 1%;
               width                : 600px;
               display              : block;
               margin               : auto;
               font-family          : Poppins;
               font-size            : 16px;
               font-weight          : 300;
               font-style           : normal;
               font-stretch         : normal;
               line-height          : 1.53;
               letter-spacing       : 2.04px;
               text-align           : center;
               text-decoration      : none;
               color                : rgba(33, 33, 33, 0.55);
               -webkit-border-radius: 37px;
               -moz-border-radius   : 37px;
               -ms-border-radius    : 37px;
               -o-border-radius     : 37px;
          }
     }
}

// Responsive
@media screen and (min-width: 10px) and (max-width: 760px) {
     h1{
          font-size: 25px!important;
     }

     h2{
          font-size: 15px!important;
     }
     
     .link{
          font-size: 13px!important;
          width: 90%!important;
     }

     .conteneur{
          height: 85vh!important;
     }
   }