*{
    padding: 0;
    margin: 0;
    overflow-x: hidden;
    overflow-y: hidden;
}

body{
    width: 100%;
    overflow: hidden;
}

// Bootstrap 
.row {
    margin-right: 0px!important;
    margin-left: 0px!important;
}

// Bootstrap 
h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0rem;
}

#copyright{
    text-align: center;
    margin: auto;
    position: absolute;
    bottom: 3%;
    left: 50%;
    transform: translateX(-50%);
    font-family: Poppins;
    font-size: 10px;
    font-weight: 300;
    color: rgba(33, 33, 33, 0.55);
    z-index: 999;
}



@font-face {
    font-family: "ModernEraBold";
    src: url("../../Font/ModernEra-Bold.otf");
}

@font-face {
    font-family: "ModernEraRegular";
    src: url("../../Font/ModernEra-Regular.otf");
}